<template>
  <div class="work-page">
    <JobOffer />
  </div>
</template>


<script>
import JobOffer from "@/components/JobOffer.vue";

export default {
  name: "WorkPage",
  components: {
    JobOffer
  }
};
</script>

<style>
  .work-page {
  background: #E1F2F9;
  min-height: calc(100vh - 100px);
  position: relative;
  display: flex;
  justify-items: center;
  align-items: center;
}
</style>