











import { Component, Vue } from "vue-property-decorator";
import { InterviewIlustration, HiringIlustration } from "./ilustrations";

@Component({
  components: {
    InterviewIlustration,
    HiringIlustration
  }
})
export default class JobOffer extends Vue {}
